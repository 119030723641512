@import '../variables';

.icon {
  &--edit {
    align-items: center;
    background-color: #ede6d8;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 #d5c9aa;
    color: $black;
    cursor: pointer;
    display: flex;
    height: 54px;
    justify-content: center;
    width: 54px;
  }

  &--arrow {
    fill: $white;
    width: 26px;
  }
}
