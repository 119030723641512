@import '../../../../assets/variables';

.page--process-list {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;

  .header {
    padding: 0 70px;
    justify-content: center;
    position: relative;

    .icon--arrow {
      position: absolute;
      left: 35px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .no-products-with-missing-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    font-size: 11px;
    font-family: $font-family-medium;

    &__text {
      margin-bottom: 22px;
    }
  }

  .content {
    margin-top: 20px;
    background-color: #faf8f3;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__header {
      color: #131111;
      font-size: $font-size-xl;
      font-family: $font-family-medium;
      padding: 35px;
      width: 100%;
    }

    .list {
      flex: 1 1;

      &__header {
        .list__cell {
          color: #66686c;
        }
      }

      &__body {
        flex: 1 1;
      }

      &__row {
        margin-bottom: 5px;
      }

      &__cell {
        &:first-child,
        &:nth-child(2) {
          margin-right: 38px;
          width: 93px;
        }

        &:nth-child(3) {
          margin-right: 21px;
          width: 50px;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-right: 21px;
          width: 150px;
        }

        &:last-child {
          margin-left: auto;
          margin-right: 0;
          width: 120px;
        }
      }

      &__item--detail {
        padding-bottom: 15px;

        .list-info {
          .header {
            background-color: $white;
            justify-content: flex-start;

            .text {
              color: #131111;
              font-family: $font-family-primary;
              font-size: $font-size-m;
              margin-right: 73px;

              &--bold {
                color: $black;
                font-family: $font-family-bold;
                margin-right: 18px;
              }
            }
          }
        }

        .btn {
          background-color: #ede6d8;
          border-radius: 2px;
          box-shadow: 0 2px 3px 0 rgba(163, 149, 120, 0.52);
          color: #131111;
          font-family: $font-family-medium;
          font-size: $font-size-m;
          height: 62px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 60px);
        }
      }
    }
  }

  .banner {
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: white;
      padding: 5px;
    }
    .list {
      margin-top: 10px;
      text-align: center;
      .list-row {
        text-align: center;
      }
      .list-item {
        padding: 10px;
      }
    }
  }
}

.red {
  .title {
    background-color: red;
  }
}

.green {
  .title {
    background-color: green;
  }
}

.modal--confirm {
  align-items: center;
  justify-content: space-between;

  .icon--track,
  .icon--picking {
    margin-bottom: 34px;
  }
}

.list--products {
  .list__cell {
    flex: 1.2;
    @media (min-width: 768px) {
      &:nth-child(4), 
      &:nth-child(5) {
        flex: 2.5;
      }
    }
  }

  .list__row {
    margin-bottom: 5px;

    &--expanded {
      .list__item {
        box-shadow: 0 10px 11px 0 rgba(147, 147, 147, 0.18);
      }
    }
  }

  .list__header {
    .list__cell {
      font-weight: 500;
      font-family: $font-family-medium;
      color: #66686c;
    }
  }
}

