@import '../../../assets/variables';

.offline-banner {
  background-color: red;
  text-align: center;
  height: 50px;
  padding-top: 5px;
}

.offline-banner-text {
  font-weight: bolder;
  color: white;
  font-size: 34px;
}
