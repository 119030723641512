@import '../../../assets/variables';

.avatar-list {
  display: flex;
  flex-direction: row-reverse;

  .avatar {
    background-size: contain;
    background-repeat: no-repeat;
    height: 29px;
    width: 29px;

    &:not(:last-child) {
      margin-left: 5px;
    }
  }

  &__count {
    align-items: center;
    background-color: #e1d3ab;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-family: $font-family-medium;
    font-size: $font-size-xs;
    justify-content: flex-end;
    line-height: 0.8;
    height: 29px;
    margin-left: -5px;
    padding-right: 5px;
    width: 29px;
  }

  &__name {
    display: flex;
    flex-direction: row;
    color: $third-text-color;
    font-family: $font-family-medium;
    font-size: $font-size-xxs;
    justify-content: center;
    line-height: 0.8;
    margin-left: 5px;
  }
}

.card {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  margin: 0 12px;
  position: relative;

  .avatar {
    height: 46px;
    margin-bottom: 5px;
    width: 46px;

    .icon--check {
      display: none;
    }

    &--success {
      .CircularProgressbar .CircularProgressbar-path {
        stroke: #417d36;
      }

      .icon--check {
        display: inline-flex;
        position: absolute;
        top: 33px;
        right: 12px;
      }
    }
  }

  .badge {
    margin-bottom: 5px;
  }

  .text {
    color: $black;
    font-size: $font-size-xs;
    font-family: $font-family-medium;
    line-height: 1;
    margin-bottom: 5px;

    &.card__load {
      color: #c5b68c;
      font-size: $font-size-xxs;
      font-family: $font-family-primary;
    }
  }
}

.card-list {
  display: flex;
  justify-content: space-between;
  padding: 0 36px;

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 650px;
    margin: 0 -12px -10px;

    .card {
      margin-bottom: 10px;
    }
  }

  .icon--edit {
    flex-shrink: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
// Container Unload / Assign / Assign To
.avatar {
  border-radius: 50%;
  margin-bottom: 5px;
  overflow: hidden;
  height: 46px;
  width: 46px;

  .checkmark {
    background-color: $buttered-rum-rgba;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    padding: 12px;
    width: 46px;
  }
}
