@import '../../../assets/variables';

.grand-total-tasks {
  position: absolute;
  left: 7%;
  align-items: center;
  background-color: $tomato;
  border-radius: 50px;
  color: $white;
  display: flex;
  font-family: $font-family-bold;
  font-size: $font-size-s;
  height: 28px;
  justify-content: center;
  margin-left: auto;
  width: 28px;

  &--primary {
    @extend .grand-total-tasks;
    left: 12%;
  }

  &--secondary {
    @extend .grand-total-tasks;
    border: 1px solid $tomato;
    color: $tomato;
    left: 7%;
    background-color: $white;
  }

  @media screen and (max-width: 600px) {
    &--primary {
      @extend .grand-total-tasks;
      left: 15%;
      height: 20px;
      width: 20px;
    }
    &--secondary {
      @extend .grand-total-tasks;
      border: 1px solid $tomato;
      color: $tomato;
      background-color: $white;
      left: 5%;
      height: 20px;
      width: 20px;
    }
  }
}
