@import '../../../../assets/variables';

.page--container-list {
  display: flex;
  flex-direction: column;
  position: relative;

  .print-upc, .hazard-class {
    font-size: 40px;
    cursor: pointer;
  }

  .edit-case-quantity-pencil {
    font-size: 20px;
    cursor: pointer;
  }

  .print-pallet-label {
    font-size: 40px;
    cursor: pointer;
  }

  .header {
    align-items: center;
    display: flex;
    padding: 0 60px 0 24px;
    justify-content: space-between;

    .title {
      font-family: $font-family-medium;
    }

    .text {
      font-size: $font-size-l;
    }

    .avatar-list {
      .avatar {
        height: 48px;
        width: 48px;

        &:not(:last-child) {
          margin-left: -10px;
        }
      }
    }
  }

  .errors-card {
    margin: 10px 30px 10px 30px;
    padding: 10px;
    width: 100%;
  }

  .error-item {
    margin: 5px 0px 5px 0px;
  }
  .content {
    background-color: #faf8f3;
    display: flex;
    flex: 1 1;
    flex-direction: column;

    &__header {
      align-items: center;
      display: flex;
      padding: 30px;
      width: 100%;

      .text {
        margin-right: 26px;

        &:first-child {
          margin-right: 68px;
        }
      }

      .toggle {
        margin-left: auto;
        margin-right: 40px;
      }
    }

    .list {
      color: $black;
      flex: 1 1;
      overflow-y: auto;
      margin-bottom: 0;

      &__header {
        flex: 0 0 auto;
        padding: 0 42px;
      }

      &__body {
        flex: 1 1;
        margin: 0 12px;

        &::-webkit-scrollbar {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border: none;
          background-color: #d8d8d8;
        }

        .swipe {
          &--active {
            background-color: $off-yellow;
            border-radius: 50px;
          }
        }
      }

      &__row {
        background-color: transparent;

        &:not(:last-child) {
          margin-bottom: 9px;
        }

        &--green {
          .list__item {
            border: 1px solid #7ed321;
          }
        }

        &--orange {
          .list__item {
            border: 1px solid #f5a623;
          }
        }

        &--black {
          .list__item {
            border: 1px solid black;
          }
        }

        &--error {
          .list__item {
            background-color: red;
            color: white;
          }
        }
      }

      &__item {
        background-color: $white;
        border-radius: 50px;
        box-shadow: 0 2px 5px 0 rgba(198, 198, 198, 0.5);
        position: relative;
        z-index: 1;

        &--detail {
          background-color: $white;
          box-shadow: 0 10px 11px 0 rgba(147, 147, 147, 0.18) inset;
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 37px;
          padding: 15px 16px 16px;
          position: relative;
          top: -5px;

          .pallet-cell {
            margin-top: 20px;
          }

          .svg--unload-success {
            height: 15px;
            width: 21px;
          }
        }
      }

      &__cell {
        justify-content: flex-start;
        flex: 1;

        &:nth-child(3) {
          flex: 3;
        }

        &:last-child {
          justify-content: center;
        }
      }
    }
  }

  .btn--complete {
    bottom: 24.6px;
    font-size: $font-size-xl-two;
    font-family: $font-family-medium;
    padding: 34px 140px;
    position: fixed;
    z-index: 1000;
  }

  .modal-full {
    width: 80%;
  }
}
