@import '../../../assets/variables';

.LinearProgressbar {
  align-items: center;
  display: flex;
  position: relative;

  &__text {
    color: $indian-khaki;
    font-size: $font-size-m;
    margin-right: 13px;
  }

  &__scale {
    background-color: $scotch-mist;
    border-radius: 6px;
    flex: 1;
    height: 6px;
    overflow: hidden;
  }

  &__bar {
    background-color: $orange-yellow;
    height: 100%;
  }

  &--empty {
    .LinearProgressbar {
      &__text {
        color: $gray;
      }

      &__scale {
        background-color: $desert-storm;
      }
    }
  }
}
