@import '../../../../assets/variables';

.relocate-product-page {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 680px) {
    height: 110%;
  }

  .header {
    justify-content: center;

    .text--close {
      font-family: $font-family-primary;
      font-size: $font-size-xl;
      cursor: pointer;
    }

    .title {
      font-size: 25px;
      flex: 1 0 auto;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;

      .avatar {
        margin-bottom: 0;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .content {
    flex: 1 0 auto;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    &__section {
      background-color: $floral-white;
      &--white {
        height: 100%;
      }
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $font-size-xl-two;
      font-family: $font-family-bold;

      svg {
        margin-left: 9px;
      }

      &__text {
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
  }

  .header-line {
    background-color: #343b41;
    width: 100%;
    height: 40px;
  }

  .content {
    padding: 35px 0 85px;
    height: 100%;
    background: #fff;
    align-items: center;
  }

  .buttons {
    display: flex;

    .btn + .btn {
      margin-left: 35px;
    }
  }

  .icons {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  .scan-indicator {
    height: 30px;
    width: 30px;
  }
}
