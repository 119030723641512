@import '../../../../assets/variables';

.page--auth {
  align-items: center;
  background-color: $floral-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100vh;

  .centered {
    display: flex;
    justify-content: center;
  }

  .padding {
    padding: 15px;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .page {
    &__title {
      color: $charcoal-grey;
      font-family: $font-family-bold;
      font-size: $font-size-xxxl;
      position: relative;
      white-space: nowrap;
      @media (max-width: 480px) {
        font-size: $font-size-xl;
      }

      .svg {
        position: absolute;
        left: -50%;
        top: 50%;
        transform: translateY(-50%);
        width: 54px;
        filter: invert(18%) sepia(3%) saturate(100%) hue-rotate(166deg)
          brightness(0) contrast(85%);
      }
    }
  }

  .logo {
    height: auto;
    margin: 50px auto;
    width: 130px;
  }

  .carousel {
    overflow: hidden;
    position: relative;
    // margin-bottom: 33px;
    width: 100vw;

    &__item {
      align-items: center;
      display: flex;
      flex-direction: column;
      left: 50%;
      opacity: 1;
      position: absolute;
      transition: 1s ease;
      transform: translateX(-50%);
      z-index: 1;
      @media (max-width: 480px) {
        left: unset;
        transform: translateX(10%);
      }

      &--pending {
        opacity: 0;
        left: 150%;
        transition: none;
        z-index: 0;
      }

      &--hidden {
        opacity: 0;
        left: -100%;
        z-index: 0;
      }
    }

    &--title {
      margin-bottom: 50px;

      .carousel__item {
        transition-duration: 0.5s;
      }
    }
  }

  .form {
    &--check-user {
      margin-bottom: 75px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    min-height: 45px;
    width: 100%;

    .btn {
      background-color: transparent;
      box-shadow: none;
      color: $charcoal-grey;
      font-family: $font-family-medium;
      font-size: $font-size-xl;

      .svg {
        filter: invert(18%) sepia(3%) saturate(100%) hue-rotate(166deg)
          brightness(0) contrast(85%);
        margin-left: 10px;
        width: 25px;
        overflow: visible;
      }
    }
  }

  @media (max-width: 768px) {
    .logo {
      margin: 10px auto;
      width: 60px;
    }

    .page {
      &__title {
        font-size: $font-size-xxxl;
        @media (max-width: 480px) {
          font-size: $font-size-xl;
        }
      }
    }

    .carousel {
      margin-bottom: 0;

      &--title {
        margin-bottom: 20px;
        height: 65px;
      }
    }

    .form {
      padding: 13px 28px 20px;
      max-width: 500px;

      .btn {
        padding: 19px 110px;
      }

      &__row {
        padding: 20px;
      }
    }

    .footer {
      margin-bottom: 0;
    }
  }

  @media (max-width: 680px) {
    .logo {
      margin: 20px auto 0;
      width: 50px;
    }

    .page {
      &__title {
        font-size: $font-size-l;

        .svg {
          width: 30px;
          filter: invert(18%) sepia(3%) saturate(100%) hue-rotate(166deg)
            brightness(0) contrast(85%);
        }
      }
    }

    .carousel {
      margin-bottom: 0;

      &--title {
        margin-bottom: 0;
      }
    }

    .form {
      border-radius: 20px;
      margin: 0;
      padding: 10px 10px 15px;
      width: 65vw;

      &__row {
        padding: 10px 0;
      }

      &__error {
        .svg {
          margin-right: 5px;
          width: 20px;
          filter: invert(18%) sepia(3%) saturate(100%) hue-rotate(166deg)
            brightness(0) contrast(85%);
          &--eye {
            width: 30px;
          }
        }

        .text {
          font-size: $font-size-xs;
          @media (max-width: 480px) {
            font-size: $font-size-l;
          }
        }
      }

      .btn {
        font-size: 0.6em;
        padding: 10px 50px;
        @media (max-width: 480px) {
          font-size: $font-size-l;
        }
      }
    }

    .footer {
      margin-bottom: 15px;
      min-height: unset;

      .btn {
        font-size: $font-size-s;
        @media (max-width: 480px) {
          font-size: $font-size-m;
        }
      }
    }
  }

  @media (orientation: portrait) {
    .carousel__item--pending {
      left: 0;
    }
  }

  @media (max-width: 640px) and (orientation: portrait) {
    .logo {
      margin: 10px auto;
      width: 100px;
    }

    .page {
      &__title {
        font-size: $font-size-xl;
      }
    }

    .carousel {
      overflow: visible;
    }

    .form {
      padding: 10px 14px 15px 10px;
      width: 90vw;

      &__row {
        padding: 15px 0;
      }

      .btn {
        font-size: $font-size-m;
        padding: 15px 60px;
      }
    }
  }
}

.modal--login-edit {
  border-radius: 24px;

  .title {
    align-self: center;
  }

  .btn {
    font-family: $font-family-medium;
    text-transform: uppercase;
  }

  @media (max-width: 680px) {
    padding: 30px;
    top: 40%;
    width: 70%;

    .title {
      font-size: $font-size-xl;
    }

    .btn {
      margin-top: 30px;
      padding: 15px 80px;

      &--close {
        bottom: -70px;
      }
    }
  }

  @media (max-width: 680px) and (orientation: portrait) {
    padding: 30px;
    top: 50%;
    width: 100%;

    .btn {
      padding: 20px 80px;
      margin-top: 50px;

      &--close {
        bottom: -93px;
      }
    }
  }
}
