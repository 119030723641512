@import '../../../assets/variables';

.page--inventory-scan {
  &.content {
    background-color: $white;
  }
  .content__section--white {
    padding: 293px 0 50px;
    background-color: $floral-white;
  }

  .text--close {
    @media (max-width: 480px) {
      right: 5% !important;
    }
  }

  .inv-btn {
    min-width: 390px;
    margin-top: 162px;
    margin-bottom: 20px;
    padding: 35px 15px;
    font-family: $font-family-primary;
    font-size: $font-size-xl-two;
    
    @media (max-width: 480px) {
      min-width: 0px !important;
      margin-top: 75px !important;
      margin-bottom: 0 !important;
      padding: 20px !important;

      font-family: $font-family-primary;
      font-size: $font-size-l;
    }
  }

  .content__input-box {
    padding-top: 70px;
    background-color: $white;
  }

  .input-box {
    position: relative;
    width: 80%;
    max-width: 426px;
    padding: 3%;
    font-family: $font-family-medium;
    font-size: $font-size-l;
    border: solid 1px $charcoal-grey;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.5);

    .status {
      position: relative;
    }

    &__input {
      width: 100%;
      padding: 3%;
      border: none;
      outline: none;
      font-family: $font-family-medium;
      font-size: $font-size-l;
      text-transform: capitalize;

      &::placeholder {
        color: $darker-grey;
      }
    }

    &__clear-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      background-color: $charcoal-grey;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 11px;
        background-color: $white;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &__list-item {
      position: relative;
      margin-top: 22px;

      &:first-of-type {
        margin-top: 44px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -26px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #e3e3e3;
        }
      }
    }
  }

  .content__print-btn {
    position: absolute;
    top: 0;
    right: -200px;
    padding: 18px 58px;
    border: none;
    border-radius: 6px;
    background-color: $dodger-blue;

    font-family: $font-family-medium;
    font-size: $font-size-xl;
    color: $white;
  }
}
@media (max-width: 890px) {
  .page--inventory-scan {
    .content__print-btn {
      top: initial;
      bottom: -100px;
      right: 50%;
      transform: translateX(50%);
    }

    .content__input-box {
      padding-bottom: 80px;
    }
  }
}
