@import '../../../../assets/variables';

.modal--success {
  padding-top: 130px;

  .title {
    align-self: center;
    margin-bottom: 80px;
  }

  .svg {
    height: 84px;
    margin-bottom: 60px;
    width: auto;
  }
}
