@import '../../../../../assets/variables';

.pallets-to-load-list-page {
  .header {
    padding-left: 0px;
    justify-content: space-between;
  }

  .closeButton {
    position: absolute !important;
    right: 7% !important;
    font-size: $font-size-xl;
  }

  .location-name {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 0;
  }

  .order-notes-icon-ptolp {
    position: relative;
    left: 5%;
    font-size: 42px;

    path {
      stroke: white;
    }
  }
}
