.putaway-task-page {
  .fireworks__sub-name {
    font-size: 20px;
    font-weight: 800;
    color: #333;
  }

  .text--products-name {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .text--skip {
    cursor: pointer;
  }
  .scan-indicator {
    height: 30px;
    width: 30px;
  }
  .location-icon {
    height: 30px;
    width: 30px;
  }
}
