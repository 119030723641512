@import '../../../assets/variables';

.main-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.version-info {
  color: #666;
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 11px;
  @media (min-width: 768px) {
    font-size: 15px;
  }
  &__text {
    display: flex;
    flex-direction: row;
  }
}

.main-page .header {
  align-items: center;
  flex: 0 1 90px;
  justify-content: space-between;

  @media (max-width: 680px) {
    padding: 0 5px 0 19px;
    flex: 0 0 45px;
  }
}

.main-page .header .title {
  font-family: $font-family-medium;
  font-size: $font-size-xxl;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 600px) {
    font-size: $font-size-sm;
  }
}

.main-page .header .text--close {
  font-size: $font-size-l;
  height: 45px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    font-size: $font-size-xs;
  }
}

.main-page .avatar {
  display: flex;
  height: 50px;
  margin-bottom: 0;
  width: 50px;

  @media (max-width: 600px) {
    height: 25px;
    width: 25px;
  }
}

.main-page__body {
  align-content: flex-start;
  align-items: flex-start;
  background-color: $floral-white;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0;
  padding: 10px 5px;
  width: 100%;
}

.main-page .tile {
  margin: 0 5px 10px;
  max-width: calc(33.33% - 10px);

  @media (min-width: 600px) and (orientation: landscape) {
    max-width: calc(25% - 10px);
  }

  @media (max-width: 480px) {
    min-width: 45%;
  }
}

.main-page__modal.modal--choose {
  background-color: transparent;
  border: none;
  top: 40%;
  width: auto;
}

.main-page__modal .choose-list {
  flex-direction: column;
}

.main-page__modal.modal--choose .choose-list .choose-item {
  @media (min-width: 600px) {
    border-radius: 50px;
    font-size: $font-size-xl-two;
    height: 99px;
    width: 389px;
  }
}
