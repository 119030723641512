@import '../../../../assets/variables';

.modal--damaged-products {
  align-items: stretch;
  padding: 60px 30px 40px;

  .modal {
    &__header {
      align-items: center;
      display: flex;
      margin-bottom: 65px;

      .sub-title {
        font-family: $font-family-bold;
        font-size: $font-size-m;
        margin: 0 auto;
      }
    }

    &__row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 65px;
    }
  }

  .btn {
    margin-top: -10px;
  }

  .quantity-control {
    &__btn:first-child {
      margin-right: 50px;
    }

    &__btn:last-child {
      margin-left: 50px;
    }

    .text-input {
      font-size: $font-size-xxxl;
    }
  }
}
