@import '../../../../../../assets/variables';

.staff-engagement {
  position: relative;

  .l-col {
    display: block;
    overflow: scroll;
    height: 900px;

    &::-webkit-scrollbar {
      display: none;
    }

    .staff-engagement__block {
      border-radius: 22px;
      background-color: white;
      box-shadow: 5px 7px 6px 0 $brown-grey-15;
      margin: 0 0 23px 0;

      .title {
        background-color: transparent;
        color: $black;
        font-size: 22px;
        padding: 45px 25px 25px;
        position: relative;

        &:before {
          background-color: $charcoal-grey;
          content: '';
          display: inline-block;
          height: 35px;
          left: 0;
          bottom: 29px;
          position: absolute;
          width: 10px;
        }
      }

      .card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        &__items {
          max-height: 250px;
          max-width: 100%;
          overflow: auto;
          padding-top: 5px;
          margin: 0 0 20px 0;

          &::-webkit-scrollbar-thumb {
            background-color: $alto;
          }

          .card {
            .badge {
              padding: 5px 25px;

              &--circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                position: absolute;
                top: 33px;
                right: 6px;
                padding: 0;
              }
            }

            .avatar {
              position: relative;

              &--active {
                border: 1px solid $buttered-rum;

                &:before {
                  background-color: $buttered-rum-rgba;
                  border-radius: 50%;
                  bottom: 0;
                  content: '';
                  display: inline-block;
                  left: 0;
                  overflow: hidden;
                  opacity: 0.54;
                  right: 0;
                  position: absolute;
                  top: 0;
                }

                .checkmark {
                  height: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 50%;
                }
              }

              &--secondary {
                border: 2px solid $corn;
              }
            }
          }
        }
      }
    }

    .slick-list {
      width: 90%;
    }
  }
}

.r-col {
  align-items: center;
  display: flex !important;
  flex-direction: column;
  margin-top: 30px;
  position: absolute;
  right: 0;
  top: 0;

  .slick-active {
    background: $pickled-bluewood;
    border-radius: 50%;
    width: 50px;

    .btn {
      border: none;
      color: $white;
    }
  }

  .control {
    align-items: center;
    box-shadow: none;
    color: $black;
    display: flex;
    justify-content: center;
    height: 50px;
    width: 50px;
  }

  .btn {
    background-color: transparent;
    border: 1.5px solid $charcoal-grey;
    border-radius: 50%;
    box-shadow: none;
    font-size: $font-size-m;
    height: 30px;
    padding: 0;
    width: 30px;
  }

  &--active {
    background: $pickled-bluewood;
    border-radius: 50%;

    .btn {
      border: none;
      color: $white;
    }
  }
}
