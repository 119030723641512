@import '../../../../assets/variables';

.modal--system-error {
  align-items: stretch;
  padding: 60px 30px 40px;
  min-width: 50%;
  background-color: red;
  color: white;

  .modal {
    &__header {
      align-items: center;
      // display: flex;
      margin-bottom: 65px;
      color: white;

      .title {
        color: white;
      }

      .sub-title {
        font-family: $font-family-bold;
        font-size: $font-size-m;
        margin-top: 20px;
      }
    }

    &__row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 65px;
    }
  }

  .btn {
    margin-top: -10px;
  }
}
