@import '../../assets/variables';

.tasks {
  background-color: $floral-white;

  &__list {
    width: 100%;
    padding: 0 43px;
    list-style: none;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-right: 13px;
      font-size: 16px;
      color: $black-two;
      border-radius: 50%;
      font-weight: $font-weight-normal;
      border: solid 1.5px $charcoal-grey;
    }

    .text-wrapper {
      display: flex;
      align-items: center;
      padding: 0 5px;

      .text {
        margin: 0 16px 0 0;
      }

      .LinearProgressbar {
        width: 160px;
        max-width: none;
        margin-left: 10px;
      }
    }
  }
}

.tasks-item {
  width: 100%;
  max-width: 658px;
  padding: 20px 28px;
  border-radius: 22px;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(52, 59, 65, 0.07);

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 37px;
    color: $black-two;
  }

  &__title-text {
    margin-right: 15px;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
  }

  &__title-desk {
    font-size: 16px;
  }

  &__body {
    display: flex;
    padding-left: 43px;
    margin-bottom: 15px;

    .text-wrapper {
      font-size: $font-size-xl;
      padding: 0;
      min-width: auto;

      &:not(:first-child) {
        margin-left: 30px;

        &:before {
          content: '';
          display: block;
          width: 2px;
          height: 16px;
          margin-right: 30px;
          background: $black;
        }
      }

      .text {
        font-weight: 500;
      }
    }
  }

  .LinearProgressbar {
    margin-left: 43px;
  }
}
