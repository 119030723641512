@import '../../../assets/variables';

.header {
  align-items: center;
  background-color: $black-light;
  color: $white;
  display: flex;
  position: relative;
  white-space: nowrap;
  flex: 0 0 90px;
  padding: 15px 25px 15px 37px;

  @media (max-width: 680px) {
    flex: 0 0 45px;
  }

  .title {
    font-size: $font-size-xxxl;
    @media (max-width: 600px) {
      font-size: $font-size-sm;
    }
  }
}

.stock_dot {
  width: 20px;
  height: 20px;
}
