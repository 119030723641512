@import '../../../../../assets/variables';

.popup--staff-detail {
  background-color: $floral-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .header {
    align-items: center;
    background-color: $black-light;
    color: $white;
    display: flex;
    justify-content: center;
    position: relative;

    .title {
      text-align: center;
      font-size: 24px;
    }

    .btn--empty {
      position: absolute;
      right: 61px;
    }
  }

  .desc {
    align-items: flex-start;
    display: flex;
    padding: 70px 43px 50px;

    .avatar {
      height: 120px;
      margin: 0 40px 0 0;
      width: 120px;

      .icon__img {
        width: 100%;
      }
    }

    &__info {
      padding-top: 27px;
      flex: 1;

      .title {
        font-size: $font-size-xxl;
        font-family: $font-family-bold;
        margin-bottom: 25px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;

        .badge {
          background-color: $beige;
          border-radius: 16px;
          border: none;
          color: $white;
          font-size: $font-size-m;
          justify-content: center;
          height: auto;
          margin-right: 12px;
          margin-bottom: 12px;
          padding: 5px 30px;
          max-width: 115px;
          white-space: nowrap;

          &--active {
            background-color: $corn;
          }
        }
      }
    }
  }

  .list__wrapper {
    position: relative;
    flex: 1;
    overflow: hidden;
    width: 100%;
  }

  .list {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    right: 5px;
    max-height: 100%;

    &::-webkit-scrollbar-thumb {
      background-color: $alto;
    }

    &__header {
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 34px;

      .badge {
        border: 1.5px solid $charcoal-grey;
        border-radius: 50%;
        font-size: $font-size-m;
        justify-content: center;
        padding: 0;
        height: 30px;
        margin-right: 13px;
        width: 30px;
      }

      .title {
        font-family: $font-family-bold;
        font-size: $font-size-xxl;
        margin-right: 15px;
      }
    }

    &__item {
      background-color: $white;
      box-shadow: 5px 7px 6px 0 $brown-grey-15;
      border-radius: 22px;
      max-width: 658px;
      margin-bottom: 12px;
      padding: 20px 0;
      width: 100%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 40px;

      .text {
        font-size: $font-size-xl;
        margin: 0 32px 22px;
        white-space: nowrap;

        &:nth-child(2) {
          position: relative;

          &:after,
          &:before {
            border: 0 solid $black-two;
            content: '';
            display: inline-block;
            height: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &:after {
            border-width: 0 0 0 1px;
            right: -32px;
          }

          &:before {
            border-width: 0 1px 0 0;
            left: -32px;
          }
        }
      }

      .LinearProgressbar {
        max-width: 546px;
        width: 100%;
      }
    }
  }
}
