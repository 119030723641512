@import '../../../../assets/variables';

.page--restock {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    justify-content: center;
  }

  .content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background-color: #faf8f3;

    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 78px 0 54px;
      background-color: #faf8f3;
      @media (min-width: 768px) {
        padding: 135px 0 95px;
      }

      &--white {
        background: $white;
        font-family: $font-family-bold;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 85px;
      }
    }
  }

  .list--restock {
    .list__cell {
      flex: 1.2;
      @media (min-width: 768px) {
        &:nth-child(6) {
          flex: 2.5;
        }
        &:last-child {
          flex: 0.85;
        }
      }
    }

    .list__row {
      margin-bottom: 5px;

      &--expanded {
        .list__item {
          box-shadow: 0 10px 11px 0 rgba(147, 147, 147, 0.18);
        }
      }
    }

    .list__header {
      .list__cell {
        font-weight: 500;
        font-family: $font-family-medium;
        color: #66686c;
      }
    }

    .list__item--detail {
      padding: 30px 30px 15px;
    }

    .details {
      .btn {
        background-color: #ede6d8;
        border-radius: 2px;
        box-shadow: 0 2px 3px 0 rgba(163, 149, 120, 0.52);
        color: #131111;
        font-family: $font-family-medium;
        font-size: 16px;
        height: 62px;
        width: 100%;
      }

      &__item {
        display: flex;
        margin-bottom: 20px;
      }

      &__text {
        flex: 1;
        &:first-child {
          flex: 2;
        }
        &:nth-child(2) {
          flex: 3;
        }

        .text--bold {
          margin-right: 30px;
        }
      }
    }
  }

  .text--close {
    position: absolute;
    right: 55px;
    font-size: 18px;
    top: 15px;
    @media (min-width: 680px) {
      top: 35px;
    }
  }

  &-relocation {
    .dock__label {
      font-family: $font-family-bold;
    }
    .fireworks-name {
      margin-bottom: 25px;
    }
    .tooltip {
      left: 0;
    }
    .content {
      &__section {
        padding-bottom: 15px;
        &--white {
          padding-top: 40px;
          font-family: $font-family-bold;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }
}

.page--restock-dmg {
  .content__header {
    display: flex;
    padding: 30px 30px 50px;
    font-size: 22px;

    .text + .text {
      margin-left: 150px;
    }
  }

  .list--restock-dmg {
    padding: 0 12px;

    .list__header {
      .list__cell {
        color: #000;
      }
    }

    .list__cell {
      max-width: 150px;
    }

    .list__row {
      border-radius: 37px;
    }
  }

  .btn {
    border-radius: 50px;
    color: $black;
    width: 390px;
    height: 100px;
    font-size: 25px;
    margin: auto 0 25px;
  }

  .status {
    margin: auto;
    font-size: 40px;
    font-family: $font-family-bold;

    svg {
      width: 30px;
      height: 30px;
      margin-left: 18px;
    }
  }
}

.icon--restock.icon--exclamation {
  max-height: 44px;
}

.restock-qty {
  font-size: 12px;
  margin-bottom: -3px;
}
