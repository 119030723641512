.background {
  background-color: white;
  justify-content: center;
  padding: 30px;
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}
