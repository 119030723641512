@import '../../../../assets/variables';

.modal--scan-damaged-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 31px 35px 22px 31px;
  border-radius: 4px;
  width: fit-content;

  .button {
    width: 100%;
    display: flex;
    padding: 0 7px 0 15px;

    .btn {
      width: 100%;
    }
  }

  &__text {
    font-size: 28px;
    font-weight: $font-weight-bold;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 22px;
  }

  .dock {
    margin-bottom: 8px;
    width: 100%;
    padding-left: 15px;

    &__label {
      padding: 21px 36px 21px 35px;
      margin-right: 17px;
      font-size: 21px;
      border-radius: 5px;
      font-family: $font-family-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    &__name {
      font-size: 77px;
      flex: 1;
      text-align: center;
    }

    &__icon {
      width: 30px;
      height: 30px;
      margin-left: 25px;
    }
  }
}
