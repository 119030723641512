@import 'assets/variables.scss';

.tasks-page {
  .text--close {
    position: absolute;
    right: 55px;
    font-size: 18px;
  }

  .item--rush {
    border: 2px solid red;
  }

  .rush-icon {
    position: absolute;
    top: 2px;
    left: 2px;
    color: red;
    font-size: 16px;
    @media (max-width: 480px) {
      position: absolute;
      top: 50px;
      left: 8px;
      color: red;
      font-size: 32px;
    }
  }

  .text--team {
    position: absolute;
    right: 10px;
  }

  .item--status-13 {
    border: 1px solid orange;
    background-color: #fff5e6;
  }

  .item--status-6 {
    background-color: #d22d2d;
    color: #f2f2f2;
  }

  .item--weight-count {
    margin-right: 5px;
  }

  .reprints--button {
    position: absolute !important;
    left: 27% !important;
    font-size: $font-size-xs;
  }

  .auto--toggle {
    position: absolute !important;
    left: 2% !important;
  }

  .picking--title {
    position: absolute !important;
    right: 35% !important;
  }
}
