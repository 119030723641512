@import '../../../assets/variables';

.btn {
  align-self: center;
  align-items: center;
  background-color: $off-yellow;
  border: none;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgba(180, 131, 19, 0.61);
  color: $primary-text-color;
  display: flex;
  font-family: $font-family-primary;
  font-size: $font-size-l;
  justify-content: center;
  height: auto;
  padding: 10px 32px;
  width: auto;
  @media (max-width: 480px) {
    padding: 10px !important; 
    margin: 5px !important;
  }

  &:active {
    opacity: 0.9 !important;
  }

  &:disabled {
    color: #a3a3a3;
    background-color: #cccccc;
    box-shadow: none !important;
  }

  &--empty {
    background-color: transparent;
    border: none;
    color: $white;
    font-size: $font-size-l;
    font-family: $font-family-primary;
  }

  &--disabled {
    color: #a3a3a3;
    background-color: #cccccc;
    box-shadow: none !important;
  }

  &--transparent {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
    box-shadow: none;
  }
}
