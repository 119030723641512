@import '../../../../assets/variables';

$check-height: 48px;
$check-width: 19px;

.modal--pending {
  align-items: center;
  font-size: $font-size-xl;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;

  .spinner {
    border-radius: 6px;
    padding: 45px 67px;
    background-color: rgba(34, 35, 39, 0.84);
    text-align: center;
    .text {
      margin-top: 30px;
      color: white;
    }
    &__animate {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;

      & div {
        transform-origin: 40px 40px;
        animation: spinner 1.2s linear infinite;

        &:after {
          content: ' ';
          display: block;
          position: absolute;
          top: 7px;
          left: 37px;
          width: 6px;
          height: 18px;
          border-radius: 50px;
          background: #ffffff;
        }

        &:nth-child(1) {
          transform: rotate(0deg);
          animation-delay: -1.1s;
        }

        &:nth-child(2) {
          transform: rotate(30deg);
          animation-delay: -1s;
        }

        &:nth-child(3) {
          transform: rotate(60deg);
          animation-delay: -0.9s;
        }

        &:nth-child(4) {
          transform: rotate(90deg);
          animation-delay: -0.8s;
        }

        &:nth-child(5) {
          transform: rotate(120deg);
          animation-delay: -0.7s;
        }

        &:nth-child(6) {
          transform: rotate(150deg);
          animation-delay: -0.6s;
        }

        &:nth-child(7) {
          transform: rotate(180deg);
          animation-delay: -0.5s;
        }

        &:nth-child(8) {
          transform: rotate(210deg);
          animation-delay: -0.4s;
        }

        &:nth-child(9) {
          transform: rotate(240deg);
          animation-delay: -0.3s;
        }

        &:nth-child(10) {
          transform: rotate(270deg);
          animation-delay: -0.2s;
        }

        &:nth-child(11) {
          transform: rotate(300deg);
          animation-delay: -0.1s;
        }

        &:nth-child(12) {
          transform: rotate(330deg);
          animation-delay: 0s;
        }
      }
    }
  }

  @keyframes spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
