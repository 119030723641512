@import '../../../../../assets/variables';

.modal--measurement-popup {
  padding: 48px 30px 34px;
  width: 620px;
  max-height: none;

  .badge {
    background-color: #3e3e3e;
    border: none;
    border-radius: 18px;
    color: $white;
    font-size: $font-size-s;
    font-family: $font-family-medium;
    height: auto;
    margin-right: 35px;
    padding: 6px 12px;
  }

  .title {
    color: $black;
    font-family: $font-family-bold;
    font-size: 30px;
  }

  .text {
    margin-right: 12px;
  }

  .btn {
    border-radius: 50px;
    color: $black;
    font-size: $font-size-l;
    height: auto;
    margin-top: 35px;
    padding: 27px 110px;
    width: auto;
  }

  .text-input {
    color: #131111;
    font-family: $font-family-primary;
    margin-right: 5px;
    max-width: 50px;
    outline: none;
  }

  .quantity-control {
    .text {
      font-family: $font-family-bold;
    }
  }

  .modal {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      width: 100%;
    }

    &__body {
      overflow: auto;
      padding-right: 5px;
      width: 100%;
    }

    &__item {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 60px;
      }

      &--addition {
        margin-top: -20px;
      }

      &--active {
        .badge {
          background-color: #f5a623;
        }

        .text-input {
          border-bottom: 1px dashed #131111;
        }
      }
    }

    &__data {
      flex: 1;

      &:nth-child(5) {
        flex: 1.3;
      }
    }
  }

  @media (min-width: 1024px) {
    top: calc(50% - 44px);

    .btn {
      margin-top: 30px;
    }
  }
}
