@import '../variables';

.badge {
  align-items: center;
  border: solid 1px #d5c8af;
  border-radius: 10.5px;
  display: flex;
  color: #131111;
  font-size: $font-size-xs;
  height: 21px;
  padding: 5px;
  text-align: center;
  width: auto;

  &--secondary {
    background-color: #d5c8af;
    color: $white;
  }
}
