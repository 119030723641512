@import '../../../../assets/variables';

.tasks-page {
  &--scan-resolution {
    min-height: unset;

    .content__section--white {
      font-family: $font-family-bold;
      &-case {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
      }
    }

    .dock__label {
      font-family: $font-family-bold;
    }

    .status {
      font-family: $font-family-bold;
      line-height: $font-lh-l;
    }

    @media (max-width: 680px) {
      .header {
        font-size: $font-size-l--two;
        flex: 0 0 45px;
      }
      .text--close {
        top: 15px;
      }
      .content {
        &__section {
          padding: 54px 0 35px;

          &--white {
            &-loc {
              padding-top: 40px !important;
            }
          }
        }
      }
    }
    @media (min-width: 768px) {
      .header {
        font-size: $font-size-xxl;
        flex: 0 0 90px;
      }
      .text--close {
        top: 35px;
        font-size: $font-size-xl;
      }
      .content {
        &__section {
          padding: 135px 0 95px;

          &--white {
            &-loc {
              padding-top: 85px !important;
            }
          }

          .dock__name {
            font-size: 100px;
          }

          .dock__label {
            width: 105px;
            height: 50px;
            font-size: 27px;
            margin-right: 40px;
            border-radius: 5px;
          }
        }
      }
      .status {
        font-size: 40px;
        svg {
          margin-left: 18px;
        }
      }
    }
  }

  &--damage-resolution {
    .content__info {
      background-color: $floral-white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      font-size: $font-size-l;
      margin-bottom: 30px;

      &-item {
        padding: 45px 53px 17px 85px;
        min-width: 145px;
        @media (max-width: 480px){
          font-size: $font-size-xl;
          padding: 0px;
          min-width: 0px;
          margin: 5px;
        }

        &:first-child {
          &::after {
            @media (min-width: 481px) {
            position: relative;
            top: -22px;
            left: 100px;
            content: '|';
            color: $charcoal-grey;
            font-size: $font-size-xs;
            }
          }
        }
      }

      &-text {
        color: $gray;
      }
    }

    .btn {
      padding: 25px 0;
      min-width: 270px;
      margin-top: 27px;
      @media (max-width: 480px) {
        padding: 0px ;
        min-width: 0px !important;
        margin-top: 0px !important;
      }

      &:last-child {
        margin-bottom: 35px;
      }
    }
  }
}
