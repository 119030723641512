@import './variables';
@import './components/badge.scss';
@import './components/icon.scss';
@import './components/tasks.scss';
@import './components/tabs.scss';
@import './components/filter.scss';

html,
body,
#root {
  height: 100%;
}

body {
  overflow-x: hidden;
  font-family: $font-family-primary;
  font-size: $font-size-m;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;

  &::-webkit-scrollbar {
    background-color: $white;
    width: 0px;

    &-button {
      display: none;
    }

    &-thumb {
      border: 2px solid transparent;
      border-radius: 9px;
      background-color: $egg-shell;
      height: 60%;
      width: 14px;
    }
  }
}

*:after,
*:before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  outline: none;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.page,
.page-content-wrapper {
  height: 100%;
}

.text {
  &--bold {
    font-family: $font-family-bold;
  }

  &--light {
    font-family: $font-family-light;
  }

  &--medium {
    font-family: $font-family-medium;
  }

  &--green {
    color: #417505;
  }

  &--small {
    font-size: 12px;
  }
}

//@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
//  html {
//    transform: rotate(-90deg);
//    transform-origin: left top;
//    width: 100vh;
//    height: 100vw;
//    overflow-x: hidden;
//    position: absolute;
//    top: 100%;
//    left: 0;
//  }
//}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .text--close {
    font-size: $font-size-m !important;
  }
}
