@import '../../../assets/variables';

.tasks-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: $floral-white;

  .header {
    justify-content: center;

    .title {
      flex: 1 0 auto;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;

      .avatar {
        margin-bottom: 0;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .content {
    flex: 1 0 auto;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    &__section {
      background-color: $floral-white;
      &--white {
        height: 100%;
      }
    }
  }

  .tasks {
    &__item-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }

    &__list {
      padding: 20px 22px;
    }
  }

  .tasks-item {
    max-width: 424px;
    margin: 0 15px 0 0;
    box-shadow: 0 2px 5px 0 rgba(198, 198, 198, 0.5);
    padding: 10px 14px;
    border-radius: 11px;

    &__btn {
      padding: 10px 28px;
      font-size: 11px;
      box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
      border-radius: 16px;
    }

    &__title {
      margin-bottom: 11px;
    }

    &__body {
      margin-bottom: 0;
      padding: 0;

      .text-wrapper {
        font-size: 11px;
        padding: 0;
        min-width: auto;

        &:not(:first-child) {
          margin-left: 10px;

          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 8px;
            margin-right: 10px;
            background: $black;
          }
        }
      }
    }

    .avatar {
      width: 23px;
      height: 23px;
    }

    &--picking, &--loading {
      flex-direction: row;

      .tasks-item__title {
        width: auto;
        margin-bottom: 0;
      }

      .tasks-item__body {
        margin-left: 5px;
        align-items: center;

        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 8px;
          margin-right: 15px;
          background: $black;
        }
      }
    }

    &--power-picking {
      flex-direction: row;
      position: relative;

      .tasks-item__title {
        width: auto;
        margin-bottom: 0;
      }

      .tasks-item__body {
        margin-left: 3px;
        align-items: center;

        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 8px;
          margin-right: 10px;
          background: $black;
        }
      }
    }

    .label {
      width: 15px;
      height: 15px;
      margin-right: 7px;
      font-size: 8px;
      border: solid 1px #343b41;
    }

    &__title-text {
      margin-right: 7px;
      font-size: 15px;
    }

    &__title-desk {
      font-size: 8px;
    }

    .text-wrapper {
      font-size: 11px;
    }

    .text-wrapper {
      .text {
        margin: 0 2px 0 0;
      }
    }
  }

  .tasks-item-power-pick {
    width: 590px;
    margin: 0 15px 0 0;
    box-shadow: 0 2px 5px 0 rgba(198, 198, 198, 0.5);
    padding: 10px 14px;
    border-radius: 11px;

    &__btn {
      padding: 10px 28px;
      font-size: 11px;
      box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
      border-radius: 16px;
    }

    &__title {
      margin-bottom: 11px;
    }

    &__body {
      margin-bottom: 0;
      padding: 0;

      .text-wrapper {
        font-size: 11px;
        padding: 0;
        min-width: auto;

        &:not(:first-child) {
          margin-left: 15px;

          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 8px;
            margin-right: 15px;
            background: $black;
          }
        }
      }
    }

    .avatar {
      width: 23px;
      height: 23px;
    }

    &--picking {
      flex-direction: row;

      .tasks-item__title {
        width: auto;
        margin-bottom: 0;
      }

      .tasks-item__body {
        margin-left: 15px;
        align-items: center;

        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 8px;
          margin-right: 15px;
          background: $black;
        }
      }
    }

    &--power-picking {
      flex-direction: row;
      position: relative;

      .tasks-item__title {
        width: auto;
        margin-bottom: 0;
      }

      .tasks-item__body {
        margin-left: 15px;
        align-items: center;

        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 8px;
          margin-right: 15px;
          background: $black;
        }
      }
    }

    .label {
      width: 15px;
      height: 15px;
      margin-right: 7px;
      font-size: 8px;
      border: solid 1px #343b41;
    }

    &__title-text {
      margin-right: 7px;
      font-size: 15px;
    }

    &__title-desk {
      font-size: 8px;
    }

    .text-wrapper {
      font-size: 11px;
    }

    .text-wrapper {
      .text {
        margin: 0 2px 0 0;
      }
    }
  }

  .no-tasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    font-size: 11px;
    font-family: $font-family-medium;

    .btn {
      padding: 17px 76px;
      margin-top: 15px;
      border-radius: 25px;
      font-size: 12px;
      box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
    }

    &__time {
      margin-bottom: 60px;
    }

    &__text {
      margin-bottom: 22px;
    }
  }

  &--picking {
    .content {
      align-items: center;
      padding: 50px 25px 15px;
    }

    .avatar-list {
      margin-bottom: 12px;
    }

    .avatar {
      width: 50px;
      height: 50px;
    }

    .order {
      font-size: 35px;
      font-weight: $font-weight-bold;
      color: $black-two;
      margin-bottom: 4px;
      border-bottom: 1px solid $black;
    }

    .customer {
      font-size: 11px;
      color: $black-two;
      margin-bottom: 30px;
    }

    .btn {
      width: 195px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
      font-size: 12px;
    }
  }

  &--start-picking {
    .dock {
      &__name,
      &__label {
        font-family: $font-family-medium;
      }
    }
    .fireworks {
      svg:first-child {
        padding: 25px 0 0 10px;
      }
    }

    .arrow--svg {
      transform: rotate(90deg);
      width: 14px;
      height: 12px;
      opacity: 0.8;
    }

    .fireworks__item:last-child {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
    }

    .content__section {
      .status {
        font-family: $font-family-bold;
      }
    }

    .btn {
      margin-top: 10px;
    }
  }

  &--unload,
  &--start-picking,
  &--start-restock,
  &--start-relocate,
  &--move-to-lane,
  &--putaway {
    .header {
      height: auto;
      font-family: $font-family-medium;
    }

    .content {
      &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 22px 15px 15px;

        &--white {
          background: $white;
        }
      }
    }

    .fireworks-name {
      font-size: 13px;
      font-weight: $font-weight-normal;
    }

    .location-case-qty {
      font-size: $font-size-sm;
    }

    .fireworks-name-new {
      font-size: 13px;
      font-weight: $font-weight-normal;
      margin-bottom: 5px;

      @media (max-width: 480px) {
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        display: flex;
        text-align: center;
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
    }

    .fireworks {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      width: 100%;

      &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        text-align: center;
        font-size: 12px;

        &:not(:last-child):after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: '';
          width: 0.3px;
          height: 10px;
          border: solid 0.3px $charcoal-grey;
        }
        &:nth-child(3):after {
          opacity: 0.7;
        }
      }

      &__name {
        margin-bottom: 4px;
      }

      &__sub-name {
        color: #828282;
      }
    }

    .fireworks-new, .from-location-fireworks {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      width: 110%;
      margin-top: 12px;

      &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
        font-size: 12px;

        &:not(:last-child):after {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0.3px;
          height: 10px;
          border: solid 0.3px $charcoal-grey;
        }
        &:nth-child(3):after {
          opacity: 0.7;
        }
      }

      &__name {
        margin-bottom: 4px;
      }

      &__sub-name {
        color: #828282;
      }
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $font-size-xl-two;
      font-family: $font-family-bold;

      svg {
        margin-left: 9px;
      }

      &__text {
        display: flex;
        align-items: center;
      }
    }

    .status__text + .status__text {
      margin-left: 45px;
    }

    .text-wrapper {
      display: flex;
      align-items: center;

      .text {
        margin-right: 15px;
      }
    }

    .btn {
      width: 195px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
      font-size: $font-size-xs;
    }

    .tasks-item__body {
      margin-bottom: 40px;
    }
  }

  &--move-to-lane {
    @media (max-width: 680px) {
      .header {
        font-size: $font-size-l--two;
        flex: 0 0 45px;
      }
      .refresh {
        top: 15px;
      }
    }
    @media (min-width: 768px) {
      .header {
        font-size: $font-size-xxl;
        flex: 0 0 90px;
      }
      .refresh {
        top: 35px;
        font-size: $font-size-xl;
      }
    }

    .error-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }

    .content {
      background-color: #e6e6e6;
    }

    .refresh {
      position: absolute;
      right: 55px;
      font-size: 18px;
      @media (max-width: 480px) {
        right: 5% !important;
      }
    }
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    &__content {
      padding: 0 10px;
      position: absolute;
      left: 48px;
      top: 60px;
      width: 120px;
      background: $white;
      border-radius: 1.5px;
      box-shadow: 0 1px 13px 0 rgba(198, 198, 198, 0.5);
      @media (max-width: 480px) {
        z-index: 999;
        margin-top: 17px;
        width: 220px;
      }
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-size: 11px;
      color: $black;
      line-height: 1;

      &:not(:last-child) {
        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          box-shadow: 0 1px 13px 0 rgba(198, 198, 198, 0.5);
          border-bottom: solid 0.5px #b4b4b4;
        }
      }

      &--disabled {
        color: #828282;

        svg {
          g,
          path {
            stroke: #828282;
          }

          g {
            path,
            circle {
              stroke: #828282;
            }
          }
        }

        .tooltip__icon--pause {
          g {
            path:nth-child(1) {
              fill: #828282;
            }
          }
        }
      }
    }

    &__icon {
      margin-right: 6px;
    }

    .triangle {
      display: block;
      width: 20px;
      height: 12px;
      position: absolute;
      left: 0;
      top: -10px;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 9px;
        top: 7px;
        width: 7px;
        height: 7px;
        box-shadow: 0 1px 13px 0 rgba(198, 198, 198, 0.5);
        background: $white;
        transform: rotate(45deg);
        border-radius: 1px;
      }
    }

    &__button {
      display: flex;
    }
  }

  &--start-restock {
    .tooltip {
      margin-right: auto;

      &__content {
        width: 145px;
      }
    }
  }
}

.modal--tasks {
  width: 242px;
  height: 215px;
  border-radius: 2px;
  padding: 35px 12px 17px;
  box-shadow: 2px 4px 10px 0 rgba(55, 55, 55, 0.38);
  background: #fffef9;
  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .text {
    font-size: 11px;
    margin-bottom: 6px;
  }

  .text--bold {
    margin-bottom: 6px;
    font-size: 15px;
  }

  .btn {
    margin-top: 15px;
    width: 195px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
    font-size: 12px;
    padding: 12px 55px;
  }

  .btn--close {
    bottom: -90px;
  }

  @media (max-width: 680px) {
    .btn--close {
      bottom: -38px;
    }
  }

  .icon--picking,
  .icon--track,
  .icon--pause,
  .icon--exclamation {
    margin-bottom: 16px;
  }

  .text-inner {
    display: flex;
    align-items: center;
    line-height: 1;

    svg {
      margin-right: 4px;
    }
  }
}

.modal--damaged {
  .text--damaged {
    align-self: flex-start;
    font-size: 15px;
    font-weight: $font-weight-bold;
    @media (max-width: 480px) {
      align-self: center;
    }
  }
}

.dock, .from-location {
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    margin-bottom: 5px;
  }

  &__new-wrapper {
    display: flex;
    margin-bottom: 5px;
    @media (max-width: 480px) {
      padding: 10px;
      min-width: 100%;
      display: block;
      .dock + .dock {
        margin-left: 0px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 25px;
    margin-right: 20px;
    border-radius: 3px;
    background: $charcoal-grey;
    font-size: 13px;
    font-weight: $font-weight-bold;
    color: $white;
    @media (max-width: 480px) {
      margin-bottom: 12px;
    }
  }

  &__name {
    font-size: 50px;
    font-family: $font-family-medium;
    color: $black;
    @media (max-width: 480px) {
      font-size: $font-size-xxxl;
      font-weight: $font-weight-bold;
      position: absolute;
      left: 33%;
      margin-bottom: 14px;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-left: 9px;
  }
}

.dock + .dock {
  margin-left: 20px;
}

.modal--pause,
.modal--wrong {
  .btn {
    margin: 0;
  }
}

.modal--wrong {
  justify-content: flex-start;

  .text-inner {
    margin-bottom: 15px;

    .text {
      margin: 0;
    }
  }

  .icon--alert {
    margin-bottom: 15px;
  }
}

.page--qa-custom {
  @media (min-width: 768px) {
    .content__section {
      justify-content: center;
    }

    .status {
      display: flex;
      align-items: center;
      font-size: 40px;
      font-family: $font-family-bold;

      svg {
        margin-left: 18px;
      }
    }

    .dock__name {
      font-size: 100px;
    }

    .dock__label {
      width: 105px;
      height: 50px;
      font-size: 27px;
      margin-right: 40px;
      border-radius: 5px;
    }
  }

  @media (max-width: 680px) {
    .content {
      &__section {
        padding: 54px 0 35px !important;

        &--white {
          padding-top: 40px !important;
          font-size: $font-size-xl-two;
          svg {
            margin-left: 10px;
          }
          .status {
            display: flex;
            align-items: center;
          }
        }
      }
      .dock__label {
        font-family: $font-family-bold;
      }
    }
  }

  .content__section--white {
    height: 100vh !important;
    @media (max-width: 480px) {
      height: auto !important;
    }
  }
}

.spaced-div {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;

  &__button {
    display: flex !important;
    justify-content: center !important;
  }
  .status-text {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 0;
  }

  .order-notes-icon-pp {
    margin-right: 10px;
  }

  .primary-details-text {
    font-size: $font-size-s;
    margin: 0;
    padding: 0;
  }

  .special-attention {
    display: inline-block; 
    width: 100%;
    text-align: center;
    color: red;
    font-weight: bold;
  }

  .secondary-details-text {
    font-size: $font-size-xs;
    margin: 0;
    padding: 0;
  }

  .position-left {
    min-width: 40%;
  }

  .closer-vert {
    line-height: 90%;
  }

  .product-list-text {
    @media (max-width: 480px) {
      font-size: $font-size-s;
      display: flex;
      text-align: center;

      margin-bottom: 0px;

      &__name {
        display: flex;
        text-align: center;
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }

      &__bold {
        font-weight: $font-weight-bold;
      }
      &__xs {
        font-size: $font-size-xs;
      }
    }
  }
  .right {
    text-align: right;
  }
}

.task-div-padding {
  padding: 1em;
  margin: -11px -1em;
}

.modal--pause {
  @media (max-width: 680px) {
    padding: 40px !important;
  }
}

.red {
  color: red;

  &__bold {
    color: red;
    font-weight: $font-weight-bold;
  }
}

.scan-indicator {
  height: 30px;
  width: 30px;
}

.restock-qty-task {
  font-size: 30px;
  margin-left: 10px;
  margin-bottom: -3px;
  @media (max-width: 480px) {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    position: absolute;
    right: 7%;
  }
}
