.tasks-page {
  .text--close {
    position: absolute;
    right: 55px;
    font-size: 18px;
  }

  .text--quantity {
    position: absolute;
    right: 10px;
  }

  .item--status-5 {
    background-color: #cccccc;
    color: #808080;
  }

  .item--status-6 {
    background-color: #d22d2d;
    color: #f2f2f2;
  }

  .item--restock {
    background-color: rgba(223, 128, 32, 0.6);
  }

  .item--top-stack {
    background-color: yellow;
  }

  .pallet {
    width: 125px;
    border-radius: 16px;
  }

  .pallet-status-19 {
    box-shadow: 0 3px 9px 0 rgba(180, 131, 19, 0.61);
    background-color: #80bfff;
  }

  .pallet-status-22 {
    border-radius: 16px;
    background-color: #cccccc;
    color: #808080;
  }

  .tasks-item__btn {
    width: 125px;
  }

  .restock {
    margin-left: 10px;
    background-color: #df8020;
    color: #f2f2f2;
  }

  .end-pick {
    font-size: 14px;
    padding: 5px 16px;
    margin: auto;
    margin-top: 5px;
    background-color: #df8020;
    color: #f2f2f2;

  }

  .hard-center {
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
    }
  }

  .icon--warning {
    font-size: 50px;
    color: #ffbf00;
  }

  .button-row {
    justify-content: center;
    display: flex;
  }

  .pallet-button {
    float: right;
  }

  .check-stock-icon {
    font-size: 42px;
  }

  .order-notes-icon-ppps {
    position: relative;
    left: 10%;
    font-size: 42px;

    path {
      stroke: white;
    }
  }

  .check-stock-div {
    text-align: center;
  }
}

.power-picking-pullsheet-page {
  .header {
    padding-left: 0px;
  }
}
