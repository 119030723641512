@import '../../../../../../../assets/variables.scss';

.selection--list {
  padding: 20px 0 10px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  &__card {
    margin-bottom: 20px;
    width: 96%;
    background-color: #ffda39 !important;
  }

  &__card-content {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
  }
}
