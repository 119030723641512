@import '../../../../assets/variables';

.modal--workers {
  padding: 58px 0 34px;

  .title {
    padding: 0 30px;
  }

  .text {
    font-size: $font-size-xs;
  }

  .card-list {
    justify-content: center;
    padding: 60px 60px 0;
  }
}
