@import '../../../assets/variables';

.tile {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tile--main {
  background-color: $white;
  border-radius: 5.5px;
  box-shadow: 2px 4px 10px 0 rgba(231, 231, 231, 0.38);
  padding: 20px 20px 30px;

  .tile__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 28px;

    .count {
      display: flex;
      align-items: center;
      background-color: $tomato;
      border-radius: 50px;
      color: $white;
      font-family: $font-family-bold;
      font-size: $font-size-s;
      height: 28px;
      justify-content: center;
      margin-left: auto;
      min-width: 28px;
      padding-left: 5px;
      padding-right: 5px;

      &--secondary {
        @extend .count;
        border: 1px solid $tomato;
        color: $tomato;
        background-color: $white;
        margin-left: 0;
      }
    }
  }
}

.tile--main--no-count .tile__header {
  visibility: hidden;
}

.tile--main .tile__body {
  margin: 5px 30px 30px;
  @media (max-width: 480px) {
    margin: 5px;
  }
}

.tile--main .tile__footer {
  font-size: $font-size-xl;
  font-family: $font-family-bold;
  @media (max-width: 480px) {
    font-size: $font-size-s;
    min-width: 125%;
    white-space: nowrap;
    text-align: center;
  }
}
