@import '../../../../assets/variables';

.modal--resume {
  padding: 87px 25px 0;
  width: auto;
  max-height: none;
  height: 100%;
  border: none;
  background: transparent;
  box-shadow: none;

  .btn {
    margin-top: 0;
    width: 195px;
    height: 50px;
  }

  svg {
    margin-bottom: 35px;
  }
}
