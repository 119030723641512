@import '../../../../assets/variables';

.modal--confirm {
  padding: 90px 25px 34px;

  .text--bold {
    font-size: 30px;
    margin-bottom: 7px;
  }

  .btn {
    margin-top: 70px;
    padding: 20px 140px;
  }

  .modal--content-centered {
    text-align: center;
  }
}

.modal--start-picking {
  width: fit-content;
  padding: 32px 50px 20px;

  .text {
    margin-bottom: 22px;
  }

  .btn {
    padding: 20px 140px;
    margin-top: 30px;
  }
}

.modal--login-edit {
  padding: 30px;
  width: fit-content;
  transform: translate(-50%, -62%);

  .btn--close {
    @media (max-width: 680px) {
      bottom: -42px;
    }
  }
}

.modal--picking-task {
  border-radius: 4px;
  padding: 30px 50px 25px 50px;
  width: fit-content;
  transform: translate(-50%, -55%);

  .text {
    font-size: 17px;

    &--bold {
      font-size: 21px;
    }
  }

  .icon--picking {
    width: 80px;
    height: 60px;
    margin-bottom: 18px;
  }

  .btn {
    padding: 21px 98px;
    margin-top: 16px;

    &--close {
      @media (max-width: 680px) {
        bottom: -34px;
      }
    }
  }
}

.modal--unload-start {
  border-radius: 4px;

  &__content {
    text-align: center;
  }

  .btn {
    padding: 25px 102px;
    margin-top: 65px;
  }

  @media (max-width: 680px) {
    padding: 28px 40px;
    width: fit-content;
    transform: translate(-50%, -54%);
    .text {
      font-size: 17px;

      &--bold {
        font-size: 21px;
      }
    }
    .icon--track {
      margin-bottom: 18px;
    }
    .btn {
      margin-top: 15px;
      padding: 20px 80px;

      &--close {
        bottom: -34px;
      }
    }
  }
}

.modal--report {
  padding: 60px 44px 29px;
  width: fit-content;

  .text {
    margin-bottom: 17px;
    font-family: $font-family-medium;
  }

  .btn {
    padding: 20px 90px;
    margin-top: 40px;
    font-size: 12px;
  }
}

.modal--damage-resolution {
  width: fit-content;
  padding: 50px 30px 28px;
  @media (max-width: 680px) {
    padding-top: 28px;
    transform: translate(-50%, -55%);
  }
  &__content {
    width: 100%;
    text-align: center;
    font-family: $font-family-bold;
    font-size: 22px;
  }
  &_title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
  }
  .btn {
    padding: 25px 110px;
    margin-top: 40px;
  }
}

.modal--inventory-pallet-qty {
  margin-top: 60px;
  padding: 58px 30px 40px;
  color: $black-two;
  @media (max-width: 480px) {
    margin: 0px !important;
    padding: 10px !important;
    width: 80%;
  }

  .text {
    margin-bottom: auto;
    align-self: flex-start;
    font-family: $font-family-bold;
    font-size: $font-size-xxl;
    @media (max-width: 480px) {
      margin-bottom: 0px;
      align-self: center;
      font-size: $font-size-xl;
    }
  }

  .quantity-control {
    width: 100%;
    justify-content: space-between;
    padding: 0 18px;
    @media (max-width: 480px) {
      width: 100%;
      justify-content: space-between;
      padding: 0 18px;
    }

    &__btn {
      width: 60px;
      height: 60px;
      @media (max-width: 480px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .button {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    @media (max-width: 480px) {
      width: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-left: 8px;
      padding-right: 8px;
    }

    .btn {
      width: 100%;
      margin-top: 42px;
      padding: 35px 15px;
      font-family: $font-family-primary;
      font-size: $font-size-xl-two;
      @media (max-width: 480px) {
        width: 50%;
        margin-top: 42px;
        padding: 10px;
        font-size: $font-size-xl;   
      }
    }
  }
}

.location-detail {
  margin: 20px 0;
}

.modal .location-detail .new-num-of-cases {
  font-size: $font-size-xxxl;
  color: #870f0f;
  font-family: $font-family-bold;
  border: none;
  margin-right: 5px;
  border-bottom: 1px dashed $black-two;
}

.modal .location-detail .case-capacity {
  color: $third-text-color;
  font-family: $font-family-bold;
  font-size: $font-size-m;
}
