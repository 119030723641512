@import '../variables';

.filter {
  display: flex;
  align-items: center;

  &__item {
    padding: 7px 14px;
    margin-right: 22px;
    color: $black-two;
    border-radius: 27px;
    border: solid 1px $charcoal-grey;
    cursor: pointer;

    &--active {
      color: $white;
      background: $charcoal-grey;
    }
  }
}

.tab__content {
  .filter {
    padding: 0 30px;
    margin-bottom: 40px;
  }
}
