@import 'assets/variables';

.tasks-page--wrap {
  background-color: $white;

  .header {
    font-family: $font-family-medium;
    align-items: center;
    padding: 12px 22px 10px 20px;
    flex: 0 0 45px;

    .title {
      font-size: 17px;
    }
  }

  .editable-info {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-family: $font-family-primary;
    padding: 15px 30px 5px 0;

    span,
    .text-input {
      font-size: 13px !important;
    }

    span:first-child {
      font-family: $font-family-medium;
    }
  }

  .list { 
    font-size: 14px;
    margin-top: 27px;

    &__header {
      font-family: $font-family-medium;
      padding: 10px 0;
      margin: 0 30px;
      border-bottom: $grey 0.6px solid;
      .list__cell {
        &:nth-child(3) {
          @media (max-width: 480px) {
            word-break: normal !important;
          }
        }
        &:nth-child(2) {
          @media (max-width: 480px) {
            display: flex !important;
            justify-content: center;
            }
        }
      }
    }

    .list__cell {
      flex: 0.8;

      &:nth-child(2) {
        flex: 1.6;
        @media (max-width: 480px) {
          display: block;
          text-align: center;
          }
      }

      &:nth-child(3) {
        flex: 1.2;
        @media (max-width: 480px) {
          word-break: break-all;
        }
      }

      &:last-child {
        flex: 2.7;
      }
    }

    &__item {
      height: auto;
      padding: 10px 0;
      @media (max-width: 480px) {
        padding: 2px;
      }
    }

    &__row {
      margin: 0 30px;
      border-bottom: $very-light-brown-67 0.75px solid;

      &:last-child {
        border-bottom: rgba(181, 173, 154, 0.92) 0.5px solid;
        margin-bottom: 10px;
      }
    }
  }

  .btn {
    padding: 18px 70px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .pallet-weight-screen {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;

    .input-box {
      margin-top: 50px;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.5);
      border: solid 1px $charcoal-grey;
      padding: 17px 24px 17px 45px;
      font-family: $font-family-medium;
      color: $charcoal-grey;
      display: flex;
      align-items: center;

      input {
        border: none;
        outline: none;
        width: 135px;
        padding-right: 15px;
        font-size: 30px;
        font-family: $font-family-medium;
        color: $charcoal-grey;
      }

      &__label {
        padding-left: 17px;
        font-size: 15px;
        border-left: 1px solid rgba(52, 59, 65, 0.78);
      }
    }

    .btn {
      padding: 18px 65px;
    }
  }
}
