.modal--qa {
  padding: 60px 48px 40px;

  .btn {
    width: 390px;
    height: 100px;
    font-size: 25px;
    margin-top: 40px;
  }

  .title {
    margin-bottom: 30px;
  }

  .quantity-control__text {
    font-size: 45px;
  }
}

.background {
  background-color: white;
  justify-content: center;
  padding: 30px;
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}
