.start-power-picking-page {
  .button--wrap-on-auto-pick {
    position: absolute;
    bottom: 7%;
  }

  .fireworks__sub-name {
    font-size: 20px;
    font-weight: 800;
    color: #333;
    &__smaller {
      font-size: 12px;
      line-height: 20px;
      font-weight: 800;
      color: #333;
      display: block;
      max-width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text--close {
    right: 25px !important;
  }

  .text--products-name {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .text--skip {
    cursor: pointer;
    @media (max-width: 480px){
      position: absolute;
      left: 7%;
    }
  }

  .order-notes-icon-spp {
    position: relative;
    left: 13%;
    font-size: 30px;

    path {
      stroke: white;
    }
  }
  
  .skipto--info{
    display: flex;
    justify-content: center;
    width:100%;
    @media (max-width: 480px){
      position: absolute;
      bottom: 1%;
    }
  }
  .scan-indicator {
    height: 30px;
    width: 30px;
  }
  .pick-status {
    background-color: white;
    display: flex;
    justify-content: center;
    text-align: 'center';
    font-size: 30px;
    font-weight: 900;
    color: #333;
  }
}
