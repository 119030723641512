@import '../../../../assets/variables';

.modal {
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  border: solid 0.5px $egg-shell;
  box-shadow: 3px 3px 4px 0 rgba(138, 137, 134, 0.27);
  color: $black;
  display: flex;
  flex-direction: column;
  font-family: $font-family-primary;
  height: auto;
  left: 50%;
  max-height: 540px;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 484px;
  max-width: 100%;

  .title {
    align-self: flex-start;
    color: $black;
    font-size: $font-size-xxl;
    font-family: $font-family-bold;
  }

  .text {
    color: $black;
    font-size: $font-size-m;
  }

  .btn {
    padding: 20px 110px;
    max-width: 100%;
  }

  &__body {
    max-height: 250px;
  }
}

.modal-full {
  width: 95%;
}

.btn--close {
  bottom: -90px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 680px) {
    bottom: -39px;
  }
}

//.ReactModal__Body--open {
// overflow: hidden;
// position: fixed;
// width: 100%;
// height: 100%;
//}
