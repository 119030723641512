@import '../../../../assets/variables';

.modal--pallet {
  padding: 60px 0 40px;

  .title {
    margin-left: 30px;
    margin-bottom: 70px;
  }

  .quantity-control {
    justify-content: space-evenly;
    margin-bottom: 30px;
    width: 100%;

    .text-input {
      min-width: 60px;
    }

    &__text {
      margin-left: 10px;
      margin-bottom: -20px;
      width: 110px;
    }

    &__btn {
      height: 60px;
      width: 60px;
    }
  }

  .btn {
    margin-top: 15px;
    padding: 27px 110px;
  }
}
