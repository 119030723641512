@import '../../../assets/variables';

.white-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__text {
    margin-bottom: 15px;
    font-size: $font-size-xl;
  }

  .btn {
    padding: 18px 55px;
    font-size: $font-size-xl;
  }
}
