@import '../../../assets/variables';

.toggle {
  align-items: center;
  display: flex;
  
  &__text {
    margin-right: 6px;
  }

  &__header-left{
    @media (max-width: 480px) {
      position: absolute;
      left: 4%; 
    }
  }
}
