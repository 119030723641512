@import '../../../../../assets/variables';

.popup--assignment {
  height: 100%;
  margin-top: 0px;

  .header {
    border-top-right-radius: 49px;
    border-top-left-radius: 49px;
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;

    .text {
      font-size: $font-size-l;
    }
  }

  .body {
    background-color: $floral-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 30px 10px 30px;
  }

  .title {
    font-family: $font-family-bold;
    font-size: 20px;
  }

  .row {
    .title {
      width: 100%;
      margin-bottom: 5px;
      font-size: $font-size-xxl;
    }

    .text-wrap {
      display: flex;
    }

    .text {
      position: relative;
      font-size: $font-size-xl;
      white-space: nowrap;

      &:not(:first-child) {
        &:before {
          content: '';
          position: absolute;
          left: -35px;
          height: 100%;

          border: 1px solid #000000;
        }
      }
    }

    .text + .text {
      margin-left: 70px;
    }

    .switch {
      border: 1px solid $charcoal-grey;
      border-radius: 27px;

      &__tab {
        background-color: transparent;
        color: $black-two;
        width: 147px;
        height: 48px;
        outline: none;

        &--active {
          background-color: $charcoal-grey;
          border-radius: 50px;
          color: $white;
        }

        &__text {
          font-family: $font-family-primary;
        }
      }
    }

    .avatar {
      margin: 0 25px 0 0;
    }

    .list {
      flex-direction: row;
      max-width: 450px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        position: relative;

        &--avatar-name {
          color: #000000;
          font-size: 12px;
          font-family: 'Roboto-Medium', sans-serif;
          line-height: 1;
          margin-top: 5px;
          margin-left: 7px;
        }

        &--close {
          position: absolute;
          top: 0;
          right: 16px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-color: $black-light;
          color: $white;
          font-size: 14px;
          text-align: center;

          &:before {
            content: '';
            height: 13px;
            width: 1px;
            top: 2px;
            transform: rotate(45deg);
            background-color: $white;
            position: absolute;
          }

          &:after {
            content: '';
            height: 13px;
            width: 1px;
            top: 2px;
            transform: rotate(-45deg);
            background-color: $white;
            position: absolute;
          }
        }
      }
    }
  }

  .row + .row {
    margin-top: 40px;
  }

  .row:nth-child(3) {
    margin-bottom: 27px;
    display: flex;
    align-items: center;

    .title {
      width: auto;
      margin: 0 30px 0 0;
      flex: 1 0 auto;
    }
  }

  .staff-engagement {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    height: calc(100% - 40px);
    margin-top: 40px;

    .row {
      margin-bottom: 20px;
    }
  }
}
