@import '../../../assets/variables.scss';

.pallet-icon-image-wrapper {
  position: relative;
  text-align: center;
  max-height: min-content;
  margin: 12px, 15px, 12px, 15px;
}

.pallet-text-count {
  position: relative;
  top: 4px;
  left: auto;
  font-size: $font-size-s !important;
  font-weight: $font-weight-bold !important;
}

.pallet-icon-p {
  text-align: center;
  margin-right: 30px;
  border-width: 0px;
  min-width: 30px;
}

.pallet-icon-svg {
  max-width: 30px;
  max-height: 10px;
}

.pallet-icon-weight {
  margin-top: -4px;
  font-size: 10px;
  margin-bottom: 2px;
}
