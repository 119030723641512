.tab {
  &__list {
    display: flex;
    margin-bottom: 40px;
    padding: 27px 32px 0;
  }

  &__item {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 165px;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: solid 1px #d6d6d6;

    &--active {
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: #131111;
      }
    }
  }

  &__content {
  }
}
