@import 'assets/variables';

.modal--stale {
  text-align: center;
  margin-bottom: -20px;
  margin-top: -10px;

  &__exclamation {
    margin: 0px;
    margin-bottom: -30px;
    font-size: 100px;
  }

  &__text {
    margin: 0px;
  }

  &__title {
    font-size: 24px;
    color: #cc0000;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__version {
    margin: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #666666;
  }
}
