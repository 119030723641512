@import '../../../../assets/variables';

.modal--warning {
  padding: 43px 65px 30px;
  width: fit-content;
  transform: translate(-50%, -50%);
  text-align: center;

  .text {
    margin: 15px 0;
    max-width: 250px;
  }

  .icon--exclamation {
    margin-bottom: 35px;
  }

  .btn {
    margin: 0;

    &--close {
      bottom: -90px;
    }
  }

  @media (max-width: 680px) {
    padding: 12px 60px 18px;
    transform: translate(-50%, -54%);
    .icon--exclamation,
    .text {
      margin-bottom: 14px;
    }
    .btn {
      padding: 18px 102px;
      &--close {
        bottom: -32px;
      }
    }
  }
}
