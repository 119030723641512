@import '../../../../assets/variables';

.modal.modal--case-received {
  max-height: 90vh;
  max-width: 100%;

  .title {
    width: 100%;
    padding: 60px 30px 30px;

    &__under-text {
      margin-top: 10px;
      font-size: 20px;
      color: #4b4b4b;
    }
  }

  .quantity-control {
    margin: 25px 60px 20px;
    width: 80%;

    &__btn {
      height: 60px;
      width: 60px;

      &:first-child {
        margin-right: auto;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  }

  .btn {
    margin: 15px auto 40px;
  }

  .pallet-cell {
    padding: 0 70px;
    width: 100%;

    &__icon {
      display: none;
    }

    &__info {
      margin-bottom: 15px;
    }
  }
}
