@import 'assets/variables';

.inventory-page {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 680px) {
    height: 110%;
  }

  .header {
    justify-content: center;
    display: flex;
    padding: 10px;
    .text--close {
      font-family: $font-family-primary;
      font-size: $font-size-xl;
      cursor: pointer;
    }

    .reset-button {
      background-color: red !important;
      border-radius: 10px;
      padding: 3px 5px 3px 5px;
      margin: 0px;
      margin-right: auto;
    }

    .reset-button-blank {
      padding: 3px 5px 3px 5px;
      margin: 0px;
      margin-right: auto;
    }

    .close-button {
      padding: 3px 5px 3px 5px;
      margin-left: auto;
    }

    .title {
      font-size: 25px;
      // flex: 1 0 auto;
      // text-align: center;
      // align-items: center;

      .avatar {
        margin-bottom: 0;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .content {
    text-align: center;
    // flex: 1 0 auto;
    // display: flex;
    height: 100%;
    // flex-direction: column;
    // justify-content: space-between;
    // &__section {
    //   background-color: $floral-white;
    //   &--white {
    //     height: 100%;
    //   }
    // }

    .inv-taker-aisle-bay-level {
      font-size: 40px !important;
      font-weight: bold;
      border-bottom: 1px solid black;
    }

    .location-case-qty {
      font-size: 30px !important;
    }
    .scan-location {
      font-size: 30px !important;
      // line-height: 30px;
    }

    .partial-pallet {
      background-color: yellow;
    }

    .pick-data {
      margin-top: 40px;
      margin-bottom: 80px;
      font-size: 20px !important;
    }

    .scan-pallets {
      font-size: 20px !important;
      border-top: 1px solid black;
      margin-top: 2px;
    }

    .scan-pallets-count {
      font-size: 15px !important;
    }

    .inv-taker-products-model {
      font-size: 30px;
      vertical-align: bottom;
    }

    .inv-taker-products-manf-model {
      font-size: 15px;
      vertical-align: middle;
    }

    .inv-taker-products-name {
      font-size: 16px;
    }

    .scanned-pallets {
      margin-top: 4px;
    }

    .save-button {
      width: 200px;
      height: 50px;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .delete-button {
      margin-left: auto !important;
    }

    .save-button-div {
      padding-bottom: 5px;
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .pallet-slot {
      border: 2px solid black;
      margin: 0px 20px 10px 20px;
      padding: 5px;
      font-size: 20px;
      display: flex;
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $font-size-xl-two;
      font-family: $font-family-bold;

      svg {
        margin-left: 9px;
      }

      &__text {
        display: flex;
        align-items: center;
      }
    }
  }

  .header-line {
    background-color: #343b41;
    width: 100%;
    height: 40px;
  }

  .content {
    // padding: 35px 0 85px;
    height: 100%;
    background: #fff;
    align-items: center;
  }

  .buttons {
    display: flex;

    .btn + .btn {
      margin-left: 35px;
    }
  }

  .icons {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  .scan-indicator {
    height: 30px;
    width: 30px;
  }
}

