@import '../../../assets/variables';

.swipe {
  position: relative;

  &__icon {
    position: absolute;
    right: 70px;
    max-width: 50px;
    top: 50%;
    transform: translateY(-50%);
  }

  &--active {
    background-color: #ede6d8;

    .swipe__text {
      align-items: center;
      color: $white;
      display: flex;
      justify-content: center;
      min-width: 80px;

      .svg {
        margin-left: 5px;
      }
    }
  }
}
