@import '../../../assets/variables';

.list {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }

  &__body {
    overflow-y: auto;
  }

  &__row {
    background-color: $white;

    .list__item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 75px;
      padding: 0 30px;

      &--detail {
        .header {
          box-shadow: 0 10px 11px 0 rgba(147, 147, 147, 0.18) inset;
          padding: 45px 30px 30px;
        }
      }
    }
  }

  &__cell {
    align-items: center;
    display: flex;
    width: 70px;
    @media (max-width: 480px) {
      font-size: $font-size-s;
      min-width: 50px;
      width: auto;
      margin: 0 3px 0 3px;

    }
  }

  .swipe {
    &__secondary {
      background-color: #ede6d8;
    }

    .list__row {
      height: 100%;
    }

    &:not(:last-child) {
      .list__row {
        margin-bottom: 3px;
      }
    }
  }
}
