@import '../../../assets/variables';

.quantity-control {
  align-items: center;
  display: flex;
  @media (max-width: 480px) {
    justify-content: center !important;
  }

  &__btn {
    outline: none;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  &__text {
    color: $third-text-color;
    font-family: $font-family-bold;
    font-size: $font-size-m;
  }

  .text-input {
    border: none;
    border-bottom: 1px dashed $black-two;
    color: $black-two;
    font-family: $font-family-bold;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 10px;
  }
}
