@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('/fonts/Roboto-Regular.woff') format('woff');
  src: local('Roboto-Regular'),
    url('/fonts/Roboto-Regular.ttf') format('truetype');
  src: local('Roboto-Regular'),
    url('/fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.ttf') format('truetype');
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.woff') format('woff');
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url('/fonts/Roboto-Bold.ttf') format('truetype');
  src: local('Roboto-Bold'), url('/fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto-Medium'),
    url('/fonts/Roboto-Medium.ttf') format('truetype');
  src: local('Roboto-Medium'), url('/fonts/Roboto-Medium.woff') format('woff');
}

//PATH
$sprite-path: '/css_sprites.png';

//COLORS

$apple-green: #7ed321;
$seaweed: #14c18b;

$scarlet: #d0021b;
$tomato: #ea4733;
$sienna: #ac4120;

$pinkish-orange: #ff7043;
$orangey-yellow: #f5a623;
$orange-yellow: #ff9f00;
$yellow-orange: #f3b000;
$dark-sand: #b6994e;
$copper: #a68225;
$tan: #dbba63;
$off-yellow: #ffda39;
$corn: #efc200;
$buttered-rum-rgba: rgba(151, 126, 16, 0.54);
$buttered-rum: #977e10;
$wheat: #f4d383;
$scotch-mist: #fff9dc;
$indian-khaki: #c5b68c;

$black: #000000;
$black-two: #131111;
$black-light: #343b41;
$black-lighter: #4b4b4b;
$dark-brown: #261b00;
$chocolate-brown: #3a2a00;
$greyish-brown: #3e3e3e;
$brown-grey-15: #a2a2a2;
$alto: #d8d8d8;
$greyish-brown-two: #4b4b4b;
$brown-grey-two: #74736e;

$white: #ffffff;
$egg-shell: #eee8d3;
$light-tan: #e5dcc0;
$beige: #d5c8af;
$pale: #ede6d8;
$floral-white: #faf8f3;

$grey: #808080;
$gray: #828282;
$brown-grey: #868277;
$darker-grey: #929292;
$desert-storm: #f7f6f5;
$dust: #aa996d;
$dust-52: rgba(163, 149, 120, 0.52);
$charcoal-grey: #343b41;
$pickled-bluewood: #36495c;
$slate-grey: #66686c;
$light-grey-blue: #85aedc;
$dodger-blue: #0091ff;

$very-light-brown-67: rgba(192, 183, 159, 0.67);
$very-light-brown-27: rgba(192, 183, 159, 0.27);
$beige: #cbc3b1;
$very-light-pink: #cccccc;
$very-light-pink-two: #e5e5e5;
$faded-pink: #d49aa1;

//TYPOGRAPHY
$primary-text-color: $black;
$secondary-text-color: $white;
$third-text-color: $black-two;

$font-family-primary: 'Roboto-Regular', sans-serif;
$font-family-light: 'Roboto-Light', sans-serif;
$font-family-medium: 'Roboto-Medium', sans-serif;
$font-family-bold: 'Roboto-Bold', sans-serif;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-sm: 15px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-l--two: 20px;
$font-size-xl: 22px;
$font-size-xl-two: 25px;
$font-size-xxl: 30px;
$font-size-xxxl: 36px;
$font-size-xxxxl: 48px;

$font-lh-s: 0.64;
$font-lh-m: 0.71;
$font-lh-l: 1;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-bold: bold;
