@import '../../../assets/variables';

.text-input {
  border: none;
  display: inline-flex;
  text-align: center;

  &:disabled {
    background-color: $white;
  }
}
