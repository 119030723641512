@import '../../../../assets/variables';
.modal--choose {
  padding: 11px 10px;
  width: auto;
  max-height: none;
  border: none;
  background: transparent;
  box-shadow: none;

  .choose-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -11px -10px;

    .choose-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 195px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 1px 3px 13px 0 rgba(74, 74, 74, 0.5);
      background-color: #fffef9;
      margin: 6px 5px;
      font-size: 12px;
      color: $black;
    }
  }
}
