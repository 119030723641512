@import '../../../../assets/variables';

.task-page--inventory {
  background-color: $white;

  .content {
    padding-bottom: 95px;
    @media (max-width: 480px){
      padding-bottom: 15px;
    }
    &__info {
      margin-bottom: 95px;
      padding: 60px 0;
      font-family: $font-family-medium;
      font-size: $font-size-xl-two;
      @media (max-width: 480px){
        padding: 30px 0 10px 0;
        margin-bottom: 15px;
      }
      &-item {
        position: relative;
        flex-basis: 300px;
        padding: 0 15px;
        @media (max-width: 480px){
          flex-basis: auto;
          padding: 0px;
        }

        &:not(:last-child):after {
          @media (min-width: 481px) {
            content: '|'; 
            display: block;
            position: absolute;
            top: initial;
            right: 0;
            bottom: 0;
            left: initial;
            font-size: $font-size-l;
          }
       
        }
      }

      &-text {
        margin-top: 8px;
      }
    }

    .quantity-control {
      justify-content: center;

      &__btn {
        width: 60px;
        height: 60px;

        &:first-child {
          margin-right: 128px;
        }

        &:last-child {
          margin-left: 128px;
        }
      }

      .text-input {
        font-family: $font-family-medium;
        color: $black-two;
      }
    }

    .btn {
      min-width: 390px;
      margin-top: 80px;
      padding: 35px 15px;

      font-family: $font-family-primary;
      font-size: $font-size-xl-two;
    }
  }
  .centered{
    display: flex;
    justify-content: center;
  }
  .colored{
    background-color: $floral-white;
  }

  .name-text{
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    max-width: 90%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 0 0 0;
  }
}
