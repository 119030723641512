@import '../../../../assets/variables.scss';

.background {
  background-color: white;
  justify-content: center;
  padding: 30px;
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.location-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .text {
    color: $scarlet;
    font-size: $font-size-xl;
    font-family: $font-family-bold;
  }
  .text--location {
    margin-left: auto;
  }
}
