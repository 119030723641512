@import '../../../assets/variables';

.pallet-cell {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  justify-content: flex-end;

  &__list-item {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #f2f2f2;
    padding: 4px;
    margin: 4px 6px 6px 6px;
  }

  &__partial {
    background-color: #fff5cc;
    border: 1px solid red;
    cursor: pointer;
  }

  &__aisle-bay-level {
    font-size: 10px;
  }

  &__case_count {
    font-size: 12px;
  }

  &__check_icon {
    height: 20px;
    width: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-family: $font-family-medium;
      font-size: $font-size-s;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    .pallet-cell__add {
      background-color: transparent;
      border: 1px dashed #111111;
      border-radius: 50%;
      box-shadow: none;
      height: 32px;
      margin-right: 5px;
      padding: 0;
      position: relative;
      width: 32px;

      &:before,
      &:after {
        background-color: #111;
        content: ' ';
        height: 10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 2px;
      }

      &:after {
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }

  &__info {
    border: 1px solid #343b41;
    border-radius: 5px;
    display: flex;
    margin-top: 6px;
    padding: 6px 10px;
    white-space: nowrap;

    .pallet-cell {
      &__icon {
        border-radius: 2px;
        height: 17px;
        margin-right: 7px;
        width: 17px;
      }
    }
  }
}

.item-error {
  width: 100%;
}
