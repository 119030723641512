@import '../../../../assets/variables';

.modal--wrong-product {
  .svg {
    margin-top: 34px;
    margin-bottom: 26px;
  }

  .title {
    align-self: center;
  }

  .text {
    text-align: center;
    margin-bottom: 60px;
  }

  .btn {
    margin-bottom: 34px;
    padding: 24px 96px;
  }
  .case-count{
    text-align: center;
    margin-bottom: auto;
    margin-block: auto;
  }
}
