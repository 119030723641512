@import '../../../../assets/variables';

.modal.modal--no-task {
  padding: 75px 50px 40px;

  .icon--exclamation {
    margin-bottom: 30px;
    height: 88px;
    width: 88px;
  }

  .btn {
    font-size: $font-size-xl-two;
    padding: 34px 150px;
    margin-top: 35px;
  }

  .btn--close {
    height: 51px;
    width: 51px;
  }

  @media (max-width: 600px) {
    border-radius: 2px;
    max-height: 90vh;
    max-width: 242px;
    padding: 40px 25px 20px;
    top: 45%;

    .icon--exclamation {
      margin-bottom: 15px;
      height: 44px;
      width: 44px;
    }

    .text--bold {
      font-size: $font-size-sm;
      text-align: center;
    }

    .btn {
      font-size: $font-size-xs;
      padding: 17px 75px;
      margin-top: 15px;
    }

    .btn--close {
      bottom: -45px;
      height: 25px;
      width: 25px;
    }
  }
}
