@import '../../../../../assets/variables';

$check-height: 72px;
$check-width: 29px;

.modal--reset-pin {
  align-items: center;
  background-color: $charcoal-grey;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .text {
    color: $white;
    font-size: $font-size-xxxxl;
    font-family: $font-family-bold;
  }

  .check-mark {
    background-color: $off-yellow;
    border-radius: 50%;
    height: 145px;
    margin-bottom: 57px;
    position: relative;
    width: 145px;

    &:after {
      animation-duration: 1000ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: 4px solid $white;
      border-top: 4px solid $white;
      content: '';
      left: 30%;
      top: 55%;
      position: absolute;
    }
  }

  .check-mark {
    @keyframes checkmark {
      0% {
        height: 0;
        width: 0;
        opacity: 1;
      }
      20% {
        height: 0;
        width: $check-width;
        opacity: 1;
      }
      40% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
      }
      100% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
      }
    }
  }
}
