@import 'assets/variables';

.modal--options-list {
  padding: 20px 0 10px;
  background-color: #e6e6e6;
  width: 100%;

  &__card {
    margin-bottom: 20px;
    width: 96%;
  }

  &__card-content {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
  }

  .online {
    background-color: #ffda39;
  }

  .offline {
    background-color: #bfbfbf;
  }

  .title {
    margin-left: 30px;
    margin-bottom: 30px;
  }

  .btn {
    margin-top: 15px;
    padding: 27px 110px;
  }
}
